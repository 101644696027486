"use client";

import { useRouter, usePathname } from "next/navigation";
import { useParams } from "next/navigation";

import { locales } from "../../../i18n";
import { DropdownMenu, Theme } from "@radix-ui/themes";
import { BR, US } from "country-flag-icons/react/3x2";
import Image from "next/image";

export default function LanguageSwitcher() {
  const router = useRouter();
  const pathname = usePathname();
  const params = useParams();

  if (!pathname) return null;
  if (!params) return null;

  const locale = params.locale as string;

  if (!locale) return null;

  const changeLanguage = (newLocale: string) => {
    const newPathname = pathname.replace(`/${locale}`, `/${newLocale}`);
    router.push(newPathname);
  };

  function getIcon(l: string = locale) {
    if (l === "en") {
      return <US className="w-5 h-5" />;
    }
    if (l === "pt") {
      return <BR className="w-5 h-5" />;
    }
    if (l === "zh") {
      return (
        <Image
          src="/images/china-flag.png"
          width={20}
          height={15}
          alt="China Flag"
        />
      );
    }
  }

  return (
    <Theme data-is-root-theme="false" className="bg-transparent">
      <span
        className="d-none d-xl-block mr-4 "
        style={{
          width: "5rem",
          borderRadius: "0.75rem",
          padding: "0 1rem",
          height: "3rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <span className="flex flex-row gap-2 items-center justify-center h-full">
              {getIcon()}
              <DropdownMenu.TriggerIcon
                style={{
                  marginLeft: "0.5rem",
                  color: "#4D7079",
                }}
              />
            </span>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content
            color="gray"
            style={{
              padding: "0.5rem",
              fontWeight: "bold",
            }}
          >
            {locales.map((lang) => (
              <button
                key={lang}
                onClick={() => changeLanguage(lang)}
                disabled={lang === locale}
                className="flex flex-row gap-2 items-center m-1 bg-transparent outline-none border-none"
              >
                {getIcon(lang)}
                {lang.toUpperCase()}
              </button>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </span>
    </Theme>
  );
}
